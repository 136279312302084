import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import theme from './theme/theme';
import { MyRouter } from './router/MyRouter';

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <MyRouter />
      </BrowserRouter>
    </ChakraProvider>
  );
}
